.contact-page-wrapper {
    text-align: center;
    padding: 2rem;
    background-color: #f0f4f8;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  
  .primary-heading {
    font-size: 2rem;
    margin-bottom: 1rem;
    animation: fadeInDown 0.6s ease-out;
  }

  .main {
    font-size: 2.9rem;
  }
  
  .contact-form-container {
    background-color: #ffffff;
    padding: 2rem;
    border-radius: 8px;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
    transition: all 0.3s ease;
    max-width: 400px;
    width: 100%;
  }
  
  .newsletter-form {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  
  .form-input {
    padding: 0.75rem;
    margin-bottom: 0.75rem;
    width: 100%;
    border: 1px solid #dcdcdc;
    border-radius: 4px;
    transition: border-color 0.3s ease;
  }
  
  .form-input:focus {
    border-color: #007bff;
    outline: none;
  }
  
  .input-error {
    border-color: #e74c3c;
    animation: shake 0.3s ease;
  }
  
  .error-message {
    color: #e74c3c;
    margin-bottom: 0.5rem;
    font-size: 0.875rem;
    animation: fadeIn 0.5s ease-out;
  }
  
  .secondary-button {
    padding: 0.9rem 1.75rem;
    background-color: white;
    outline: none;
    border: 2px solid #00331b;
    border-color: #00331b;
    border-radius: 5rem;
    font-size: 1.1rem;
    cursor: pointer;
    font-weight: 600;
    transition: 0.2s;
    text-decoration: none; /* Remove underline from the link */
    color: #00331b;
  }
  
  .secondary-button:hover {
    background-color: #00b761;
    color: #fff;
  }
  
  .success-message {
    color: #28a745;
    font-size: 1.25rem;
    animation: fadeInUp 0.6s ease-out;
  }
  
  /* Animations */
  @keyframes fadeInDown {
    from {
      opacity: 0;
      transform: translateY(-20px);
    }
    to {
      opacity: 1;
      transform: translateY(0);
    }
  }
  
  @keyframes fadeInUp {
    from {
      opacity: 0;
      transform: translateY(20px);
    }
    to {
      opacity: 1;
      transform: translateY(0);
    }
  }
  
  @keyframes fadeIn {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }
  
  @keyframes shake {
    0%,
    100% {
      transform: translateX(0);
    }
    20%,
    60% {
      transform: translateX(-10px);
    }
    40%,
    80% {
      transform: translateX(10px);
    }
  }
  

  /* surfin-styles.css */

.surfin-container {
  min-height: 100vh;
  background-color: #f3f4f6;
  font-family: sans-serif;
}

.surfin-nav {
  position: sticky;
  top: 0;
  background-color: white;
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1);
  z-index: 10;
}

.surfin-nav-content {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0.75rem 1rem;
}

.surfin-logo {
  font-size: 1.5rem;
  font-weight: bold;
  color: #15803d;
}

.surfin-nav-links {
  display: none;
}

.surfin-nav-link {
  color: #4b5563;
  margin-left: 1rem;
}

.surfin-nav-link:hover {
  color: #15803d;
}

.surfin-download-btn {
  background-color: #15803d;
  color: white;
  padding: 0.5rem 1rem;
  border-radius: 0.25rem;
}

.surfin-download-btn:hover {
  background-color: #166534;
}

.surfin-hamburger {
  display: block;
}

.surfin-promo-banner {
  background-color:  #00b761;
  text-align: center;
  padding: 5rem 5rem;
  border-radius: 0.5rem;
  margin-bottom: 4rem;
  margin-top: 4rem;
}

.surfin-promo-title {
  font-size: 1.875rem;
  font-weight: bold;
  color: #1f2937;
  margin-bottom: 0.5rem;
}

.surfin-promo-subtitle {
  font-size: 1rem;
  color: #fdfdfd;
  margin-bottom: 1rem;
}

.surfin-promo-form {
  display: flex;
  flex-direction: column;
  /* max-width: 36rem; */
  margin: 0 auto;
  justify-content: center;
  align-items: center;
  min-height: 50px;
  padding: 2rem 0rem 1rem 0rem; 
}

.surfin-input-wrapper {
  display: flex;
  align-items: center;
  background-color: white;
  border-color: #fff;
  border-radius: 16px 16px 16px 16px;
  overflow: hidden;
  margin-bottom: 0.5rem;
  min-height: 44px;
  width: 100%;
}

.surfin-input {
  width: 100%;
  padding: 0.5rem 1rem;
  outline: none;
  border: none;
}

.surfin-submit-btn {
  background-color: #f59e0b;
  color: white;
  font-weight: bold;
  padding: 0.5rem 1.5rem;
  border-radius: 16px 16px 16px 16px;
  transition: background-color 0.3s;
  border: none;
  min-height: 44px;
  width: 100%;

}

.surfin-submit-btn:hover {
  background-color: #d97706;
}

@media (max-width: 768px) {
  .main {
    font-size: 2rem;
  }
}

@media (min-width: 640px) {
  .surfin-promo-form {
    flex-direction: row;
  }

  .surfin-input-wrapper {
    margin-bottom: 0;
    border-radius: 16px 0px 0px 16px;
    width: 30%;

  }

  .surfin-submit-btn {
    border-radius: 0px 16px 16px 0px;
    width: 10%;

}
.surfin-promo-banner {
  background-color:  #00b761;
  text-align: center;
  /* padding: 1rem 1rem; */
  border-radius: 0.5rem;
  margin-bottom: 4rem;
  margin-top: 4rem;
}

}

@media (min-width: 1024px) {
  .surfin-nav-links {
    display: flex;
  }

  .surfin-hamburger {
    display: none;
  }
  
}

.error-message {
  color: red;
  margin-top: 10px;
}