/* Privacy.css */

.privacy-container {
    padding: 20px;
    max-width: 800px;
    margin: 0 auto;
    font-family: Arial, sans-serif; /* Adjust to match your site's font */
    color: #333; /* Adjust text color */
}

.privacy-title {
    font-size: 2.5em;
    margin-bottom: 20px;
    text-align: center; /* Center the title */
}

.privacy-section {
    margin-bottom: 30px; /* Space between sections */
}

.privacy-subtitle {
    font-size: 1.8em;
    margin-bottom: 10px;
    color: #007BFF; /* Adjust to match your site's color scheme */
}

.privacy-text {
    font-size: 1em;
    line-height: 1.6; /* Improve readability */
}

.privacy-list {
    margin-left: 20px; /* Indent list items */
    list-style-type: disc; /* Use disc style for lists */
}

.privacy-list li {
    margin-bottom: 10px; /* Space between list items */
}