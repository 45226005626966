* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "Poppins", sans-serif !important;
}
img {
  max-width: 100%;
  height: auto;
}



.App {
  min-height: 100vh;
  width: 100vw;
  max-width: 1900px;
  margin: 0rem auto;
}
body {
  max-width: 100%;
  overflow-x: hidden;
}


.navbar {
  position: sticky;
  top: 0;
  z-index: 999;
  /* border-radius: 0px 0px 16px 16px; */
  box-shadow: 0 4px 3px rgba(0, 0, 0, 0.1); /* Medium shadow */
  padding-left: 30px;
  padding-right: 10px;
  width: 101%;
  background: #f6f6f6;
}

.navbar.active {
  background: #f6f6f6;
}

nav {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 20px 20px;
}
  

.nav-logo-container {
  cursor: pointer;
}
.navbar-menu-container {
  display: none;
}
.navbar-links-container a {
  margin-right: 3rem;
  text-decoration: none;
  color: #00331b;
  font-size: 1.1rem;
  font-weight: 600;
}

.navbar-links-container a:hover {
  border-bottom: 2px solid #00331b;
}

.navbar-links-container .active {
  padding-bottom: 6px;
  border-bottom: 3px solid #00331b;
}

.primary-button {
  padding: 0.9rem 1.75rem;
  background-color: white;
  outline: none;
  border: 2px solid #00331b;
  border-color: #00331b;
  border-radius: 5rem;
  font-size: 1.1rem;
  cursor: pointer;
  font-weight: 600;
  transition: 0.2s;
}
.navbar-cart-icon {
  font-size: 1.15rem;
}

.primary-button:hover {
  background-color: rgb(5, 157, 38);
  color: #fff
  ;
}

.side-nav-button {
  padding-left: 2rem;
  padding-bottom: 2rem;
}

.navbar-menu-container svg {
  font-size: 1.5rem;
  cursor: pointer;
}
.home-banner-container {
  position: relative;
  display: flex;
  padding-top: 2rem;
}
.home-bannerImage-container {
  position: absolute;
  top: -100px;
  right: -170px;
  z-index: -2;
  max-width: 780px;
}
.home-image-section {
  margin-top: 30px;
  max-width: 800px;
  flex: 1;
  display: flex;
  align-items: center;
  padding-right: 150px;
}
.home-text-section {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  margin-left: 150px;
}
.primary-heading {
  font-size: clamp(2rem, 3.8vw, 4rem);
  color: #191919;
  line-height: 4rem;
  max-width: 680px;
  /* background-color: #f6f6f6; */
  /* margin-bottom: 20px; */
}

.primary-text-header {
  font-size: clamp(1rem, 3vw, 1.5rem);
  max-width: 600px;
  color: #454545;
  padding-top: 0rem; /* Adds space inside the top of the paragraph */
  padding-bottom: 0rem; /* Adds space inside the bottom of the paragraph */
}


.primary-text {
  font-size: clamp(1rem, 1.25vw, 1.5rem);
  max-width: 670px;
  color: #454545;
  margin: 1.5rem 0rem;
}

.green-text {
  color: #018949;
  font-weight: 300;
}

.main {
  font-size: large;
  font-weight: 300;
}

.secondary-button-work {
  display: inline-block; /* Ensure the button behaves like a button */
  padding: 0.75rem 1.5rem; /* Add some padding for better click area */
  background-color: #f5f5f5; /* Example background color */
  color: #333; /* Example text color */
  text-decoration: none; /* Remove underline from the link */
  border: none;
  border-radius: 4px; /* Rounded corners */
  cursor: pointer;
  font-weight: 500;
  font-size: 1rem; /* Adjust font size */
  transition: background-color 0.3s ease; /* Smooth hover transition */
}

.secondary-button-work:hover {
  background-color: #e0e0e0; /* Change color on hover */
}

.button-content {
  display: flex; /* Make the button content a flex container */
  align-items: center; /* Align items vertically centered */
}

.button-icon {
  margin-left: 0.5rem; /* Add some space between the text and icon */
}


.secondary-button {
  padding: 0.9rem 1.75rem;
  background-color: white;
  outline: none;
  border: 2px solid #00331b;
  border-color: #00331b;
  border-radius: 5rem;
  font-size: 1.1rem;
  cursor: pointer;
  font-weight: 600;
  transition: 0.2s;
  text-decoration: none; /* Remove underline from the link */
  color: #00331b;
}
.secondary-button svg {
  margin-left: 0.75rem;
  font-size: 1.5rem;
}
.secondary-button:hover {
  background-color: #00b761;
  color: #fff;
}
.about-section-container {
  margin-top: 10rem;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.about-background-image-container {
  position: absolute;
  left: -150px;
  z-index: -2;
}
.about-section-image-container {
  flex: 0.9;
  margin-right: 1rem;
}
.about-section-text-container {
  flex: 1;
  justify-content: center;
  display: flex;
  flex-direction: column;
}

.sub-header-line-bold {
  font-size: 1.45rem; /* 20px */
  color: #4a5568; /* Gray color equivalent */
  font-weight: 600;
  margin-top: 1rem; /* 16px */
  margin-bottom: 0.25rem; /* 16px */
}

.sub-header-line {
  font-size: 1.25rem; /* 20px */
  color: #718096; /* Gray color equivalent */
  margin-bottom: 1rem; /* 16px */
}


.primary-subheading {
  font-weight: 700;
  color: #018949;
  font-size: 1.15rem;
}
.about-buttons-container {
  /* margin-top: 2rem; */
  display: flex;
}
.watch-video-button {
  margin-left: 2rem;
  background-color: transparent;
  outline: none;
  border: none;
  border-radius: 5rem;
  font-size: 1.1rem;
  cursor: pointer;
  font-weight: 600;
  color: #1b1b1b;
  transition: 0.2s;
  display: flex;
  align-items: center;
  justify-content: center;
}
.watch-video-button svg {
  font-size: 3rem;
  margin-right: 1rem;
}
.contact-page-wrapper h1 {
  max-width: 900px !important;
  background-color: #fff;
}
.contact-page-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  margin: 1rem 0rem;
}

.contact-form-container {
  background-color: white;
  max-width: 700px;
  width: 100%;
  margin-top: 1rem;
  display: flex;
  align-items: center;
  padding: 1rem;
  border-radius: 5rem;
  align-items: center;
  justify-content: center;
}

.contact-form-container input {
  flex: 1;
  height: 100%;
  border: none;
  outline: none;
  font-size: 1.3rem;
  padding: 0.5rem 1rem;
}

.work-buttons-container {
  margin-top: 2rem;
  align-items: center;
}

.footer-wrapper {
  margin: 6rem 3rem;
  display: flex;
}

.footer-logo-container {
  max-width: 110px;
  cursor: pointer;
}
.footer-icons {
  margin-top: 2.5rem;
}
.footer-icons svg {
  font-size: 1.5rem;
  margin-right: 1.25rem;
  color: #585858;
}
/* .footer-section-one {
  border: 2px solid blue;
} */
.footer-section-two {
  flex: 1;
  display: flex;
  justify-content: flex-end;
}
.footer-section-columns {
  display: flex;
  flex-direction: column;
  min-width: 190px;
}
.footer-section-columns span {
  margin: 0.25rem 0rem;
  font-weight: 600;
  color: #4c4c4c;
  cursor: pointer;
}
.testimonial-section-bottom {
  margin-top: 2rem;
  background-color: white;
  padding: 1.5rem 2rem;
  display: flex;
  align-items: center;
  justify-content: center;
  max-width: 750px;
  border-radius: 2rem;
  flex-direction: column;
  text-align: center;
}
.testimonial-section-bottom {
  margin: 0px 10px;
}
.testimonial-section-bottom p {
  font-weight: 700;
  color: #515151;
  max-width: 500px;
  font-size: 1.1rem;
  margin: 2rem 0rem;
}
.testimonials-stars-container svg {
  margin: 0rem 0.25rem;
  font-size: 1.5rem;
  color: #fe9e0d;
}
.testimonial-section-bottom h2 {
  margin-top: 1.5rem;
}
.work-section-wrapper {
  margin-top: 10rem;
  margin-left: 10px;
  margin-right: 10px;

}
.work-section-top p {
  text-align: center;
  max-width: 600px !important;
}
.work-section-top h1 {
  max-width: 700px !important;
}
.work-section-top {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin-left: 10px;
  margin-right: 10px;
}
.work-section-info {
  width: 290px;
  min-height: 350px;
  background-color: white;
  padding: 1rem 2rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  border-radius: 1rem;
  color: #505050;
  margin: 1rem 2rem;
}
.work-section-info h2 {
  margin: 1rem 0rem;

}

.card {
  background-color: #ffffff;
  padding: 1.5rem;
  border-radius: 0.375rem;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease, box-shadow 0.3s ease; /* Smooth transition for transform and shadow */
}

.card:hover {
  transform: translateY(-10px) scale(1.05); /* Lift and slightly scale up the card */
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.15); /* Increase shadow depth for a "pop" effect */
}

.work-section-bottom {
  max-width: 1800px;
  /* margin-top: 5rem; */
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
}

.work-section-bottom-button{
  max-width: 1800px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
}

.work-section-info p {
  flex: 1;
  display: flex;
  align-items: center;
  font-weight: 600;
}

@media (max-width: 1000px) {
  .navbar-links-container a {
    margin-right: 1rem;
    font-size: 1rem;
  }
  .primary-button {
    font-size: 1rem;
  }
  .home-bannerImage-container {
    max-width: 700px;
  }
}
@media (max-width: 800px) {
  .nav-logo-container {
    max-width: 140px;
    cursor: pointer;
  }
  .navbar-links-container {
    display: none;
  }
  .navbar-menu-container {
    display: flex;
  }
  .home-bannerImage-container {
    max-width: 450px;
  }
  .home-banner-container,
  .about-section-container {
    flex-direction: column-reverse;
    align-items: center;
    justify-content: center;
    margin: 20px;

  }
  .about-section-container {
    flex-direction: column;
  }

  .sub-header-line {
    font-size: 1.1rem; /* 20px */
    color: #4a5568; /* Gray color equivalent */
    font-weight: 300;
    margin-bottom: 1rem; /* 16px */
    
  }

  .home-image-section,
  .about-section-image-container {
    width: 100%;
    max-width: 400px;
    padding-right: 0px;
  }

  
  .contact-page-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    margin: 3rem 0rem;
  }

  

  .primary-heading {
    text-align: left;
    max-width: 100%;
    padding-right: 0px;
    line-height: 3.2rem;
    font-size: 2.6rem; /* 20px */
  }

 

  .primary-text {
    text-align: left;
    max-width: 100%;
    margin: 12px auto;
  }
  

  .home-text-section,
  .about-section-text-container {
    justify-content: left;
    align-items: left;
    margin-top: 2rem;
    margin-left: 10px;
    margin-right: 10px;
  }

  .secondary-button {
    font-size: 1rem;
    padding: 0.8rem 2rem;
    cursor: pointer;
  }
  .about-section-container {
    margin-top: 5rem !important;
  }
  .about-buttons-container {
    justify-content: center;
    flex-direction: column;
  }
  .primary-subheading {
    text-align: center;
  }
  .watch-video-button {
    margin-left: 0rem !important;
    margin-top: 1rem;
    font-size: 1rem !important;
  }
  .watch-video-button svg {
    margin-right: 0.5rem !important;
  }
  .about-section-image-container {
    margin-right: 0rem !important;
  }
  .work-section-wrapper {
    margin-top: 5rem !important;
  }
  .work-section-bottom {
    margin-top: 1rem !important;
  }
  
  .contact-form-container {
    padding: 0.5rem !important;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border-radius: 1rem !important;
  }
  .contact-form-container input {
    font-size: 1rem !important;
    max-width: 100%;
    padding: 0.5rem 1rem !important;
    margin-bottom: 0.8rem !important;
    text-align: center;
  }
  .footer-wrapper {
    flex-direction: column;
    margin-left: 14px;
    margin-right: 12px;
  }
  .footer-section-two {
    flex-direction: column;
    margin-top: 2rem;
  }
  .footer-section-columns {
    margin: 1rem 0rem;
  }
  .App {
    max-width: 95vw;
  }

  .main {
    font-size: 12px;
  }
}
@media (max-width: 600px) {
  .home-bannerImage-container,
  .about-background-image-container {
    display: none;
  }

  
}

.footer-text {
  text-align: center;
  max-width: 80%;
  text-decoration: none;
  color: #585858;
  font-size: 1rem;
  /* font-weight: lighter; */
}

.footer-text:hover {
  color: #00b761;
}

